// import axios from "axios"
import Api from '../../config/config.js'

export default {
    
    async sendMailFacture(options){
        try {
            let sendMail = await Api.instance.post(`/send-mail-facture`,options)
            console.log("sendMail facture",sendMail)
            return sendMail.data
            
        }catch(error){

        }},
    async resendMailConfirmation(options){
        try {
            let sendMail = await Api.instance.post(`/resend-mail-confirmation`,options)
            console.log("sendMail confirmation",sendMail)
            return sendMail.data
            
        }catch(error){

        }},
    async getByRefCotisation(options){
        try {
            let getMembre = await Api.instance.get(`/member/get-by-ref-cotisation`,{params:options})
            console.log("getMembre",getMembre)
            return getMembre.data
        } catch (error) {
            console.log(error)
        }
    },
    async getByName(options){
        try {
            let getMembre = await Api.instance.get(`/member/get-by-name`,{params:options})
            console.log("getMembre",getMembre)
            return getMembre.data
        } catch (error) {
            console.log(error)
        }
    },
    async put(options){
        try {
            let updateMembre = await Api.instance.put(`/member/update`,options)
            console.log("updateMembre",updateMembre)
            return updateMembre.data.update
        } catch (error) {
            console.log(error)
        }
    },
    
    async putContact(options){
        try {
            let updateMembreContact = await Api.instance.put(`/member/update-contact`,options)
            console.log("updateMembreContact",updateMembreContact)
            return updateMembreContact.data.update
        } catch (error) {
            console.log(error)
        }
    },
    
    async putResponsable(options){
        try {
            let updateMembreResponsable = await Api.instance.put(`/member/update-responsable`,options)
            console.log("updateMembreResponsable",updateMembreResponsable)
            return updateMembreResponsable.data.update
        } catch (error) {
            console.log(error)
        }
    },
    
    async putUrgence(options){
        try {
            let updateMembreUrgence = await Api.instance.put(`/member/update-urgence`,options)
            console.log("updateMembreUrgence",updateMembreUrgence)
            return updateMembreUrgence.data.update
        } catch (error) {
            console.log(error)
        }
    },
    
    async putOptin(options){
        try {
            let updateMembreOptin = await Api.instance.put(`/member/update-optin`,options)
            console.log("updateMembreOptin",updateMembreOptin)
            return updateMembreOptin.data.update
        } catch (error) {
            console.log(error)
        }
    },
    
    async putAffiliation(options){
        try {
            let updateMembreAffiliation = await Api.instance.put(`/member/update-affiliation`,options)
            console.log("updateMembreAffiliation",updateMembreAffiliation)
            return updateMembreAffiliation.data.update
        } catch (error) {
            console.log(error)
        }
    },
    
    async addAffiliation(options){
        try {
            let updateMembreAddAffiliation = await Api.instance.put(`/member/add-affiliation`,options)
            console.log("updateMembreAddAffiliation",updateMembreAddAffiliation)
            return updateMembreAddAffiliation.data.add
        } catch (error) {
            console.log(error)
        }
    },
    
    async deleteAffiliation(options){
        try {
            let deleteMembreAddAffiliation = await Api.instance.put(`/member/delete-affiliation`,options)
            console.log("deleteMembreAddAffiliation",deleteMembreAddAffiliation)
            return deleteMembreAddAffiliation.data.delete
        } catch (error) {
            console.log(error)
        }
    },

    async getSearch(options){
        try {
            let searchMembre = await Api.instance.get(`/member/search-affiliation`,{params:options})
            console.log("searchMembre",searchMembre)
            return searchMembre.data
        } catch (error) {
            console.log(error)
        }
    },

    async putDelete(options){
        try {
            let updateMembredelete = await Api.instance.put(`/member/delete`,options)
            console.log("updateMembredelete",updateMembredelete)
            return updateMembredelete.data.delete
        } catch (error) {
            console.log(error)
        }
    },
}