<template>
  <v-container id="members" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
          <div class="mt-8 px-5 py-5 elevation-6 rounded">
            <div class="d-flex justify-space-between">
            <div class="d-flex action">
                <v-sheet
                color="primary "
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click="vue='voir',init()"
                style="cursor: pointer"
                >
                <v-icon size="32">mdi-clipboard-text</v-icon>
                <span class="text-caption text-uppercase">Voir</span>
                </v-sheet> 
                
                <!-- <v-sheet
                color="primary "
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click="vue='creer',getSaison(),init()"
                style="cursor: pointer"
                >
                <v-icon size="32">mdi-pencil</v-icon>
                <span class="text-caption text-uppercase">Créer</span>
                </v-sheet> -->

            </div>
            <div class="d-flex save">
                <download-excel :data="membreExport" :before-generate="membreDataSelect" :fields="jsonFields" type="xls" name="membre.xls" worksheet="membre" :stringifyLongNum="true" :escapeCsv="true">
                    <v-sheet
                    :color="membre.length && vue=='voir'?'indigo':'grey'"
                    elevation="6"
                    class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                    dark
                    :max-width="65"
                    style="cursor: pointer"
                    >
                    <v-icon  size="32">mdi-microsoft-excel</v-icon>
                    <span class="text-caption text-uppercase">Export</span>
                    </v-sheet>        
                </download-excel>
                
                <!-- Mail -->
                <v-sheet
                :color="(selected.length && vue=='voir')?'purple':'grey'"
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click.stop="selected.length?dialogMail = true:''"
                style="cursor: pointer"
                >
                <v-icon  size="32">mdi-send</v-icon>
                <span class="text-caption text-uppercase">mail</span>
                </v-sheet>
                <v-dialog
                persistent
                v-model="dialogMail"
                max-width="290"
                >
                <v-card>
                    <v-card-title class="text-h5">
                    Selectioner le type de mail
                    </v-card-title>
                    <v-card-text>
                    <v-select v-model="sendMail" :items="typeMail">

                    </v-select>
                    </v-card-text>
                    <v-card-text v-if="sendMail==1">
                    {{`Envoyer un mail à ${selected.length} destinataire(s)`}}
                    </v-card-text>
                    <v-card-text v-if="sendMail==2">
                    {{`Envoyer un mail à ${Object.keys(groupByCotisation(membre)).length} destinataire(s)`}}
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        dark
                        text
                        @click="dialogMail = false,sendMail=0"
                    >
                        annuler
                    </v-btn>

                    <v-btn
                        color="primary"
                        dark
                        @click="dialog = false,send()"
                    >
                        Envoyer
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>  
                <!-- Mail -->

                <v-sheet
                :color="(selected.length && vue=='voir')||(update.selected.length && vue=='modifier')?'red':'grey'"
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click="vue=='voir' || (vue=='modifier' && typeForm=='affiliation')?supprimer():''"
                style="cursor: pointer"
                >
                <v-icon  size="32">mdi-delete</v-icon>
                <span class="text-caption text-uppercase">Supprimer</span>
                </v-sheet>        

                <v-sheet
                :color="update.save || add.save?'indigo':'grey'"
                elevation="6"
                class="mr-5 d-flex text-start align-center justify-center flex-column v-card--material__heading mb-n6 pa-3"
                dark
                :max-width="65"
                @click="save()"
                style="cursor: pointer"
                >
                <v-icon  size="32">mdi-content-save</v-icon>
                <span class="text-caption text-uppercase">Garder</span>
                </v-sheet>
            </div>   
            </div>
            <div v-if="vue=='voir'">
                <v-simple-table>
                <thead>
                    <tr>
                    <th class="primary--text"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="d-table d-sm-flex" style="min-height:66px">
                            <v-responsive
                            class="mx-0"
                            max-width="250"
                            style="min-height:66px"
                            >
                                <v-text-field
                                :label="'Référence'"
                                color="primary"
                                hide-details
                                style="max-width: 165px;min-height:66px"
                                v-model.trim="voir.ref"
                                >
                                <template
                                    v-slot:append-outer
                                >
                                    <v-btn
                                    class="mt-n2"
                                    elevation="1"
                                    fab
                                    small
                                    color="primary"
                                    @click="searchByRef()"
                                    >
                                    <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                </v-text-field>
                            </v-responsive>
                            <v-responsive
                            class="mx-0"
                            max-width="250"
                            style="min-height:66px"
                            >
                                <v-text-field
                                :label="'Nom'"
                                color="primary"
                                hide-details
                                style="max-width: 165px;min-height:66px"
                                v-model.trim="voir.nom"
                                >
                                <template
                                    v-slot:append-outer
                                >
                                    <v-btn
                                    class="mt-n2"
                                    elevation="1"
                                    fab
                                    small
                                    color="primary"
                                    @click="searchByName()"
                                    >
                                    <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                </v-text-field>
                            </v-responsive>
                        </td>
                    </tr>
                </tbody>
                </v-simple-table>
                <v-simple-table>
                <thead>
                    <tr>
                    <th class="primary--text">Saison</th>
                    <th class="primary--text">Type</th>
                    <th class="primary--text">Categorie</th>
                    <th class="primary--text">Salle</th>
                    <th class="primary--text">Créneau</th>
                    <th class="primary--text">Statut</th>
                    <th class="primary--text"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                    <td style="vertical-align:top">
                        <v-responsive
                        class="mx-0"
                        max-width="150"
                        >
                        <v-select chips  @change="controlVoir()" v-model="voir.saison" :placeholder="'Saison'" :items="saison">
                        <!-- <v-select chips multiple @change="controlVoir()" v-model="voir.saison" :placeholder="'Saison'" :items="saison"> -->
                            <!-- <template slot="prepend-item">
                                <div v-if="saison.length" class="d-flex">
                                    <v-btn class="mx-auto mb-2" color="primary" @click="selectAll('saison',saison)">tous</v-btn>
                                </div>
                            </template> -->
                        </v-select>
                        </v-responsive>
                    </td>
                    <td style="vertical-align:top">
                        <v-responsive
                        class="mx-0"
                        max-width="150"
                        >
                        <v-select chips multiple  @change="controlVoir()" v-model="voir.type" :placeholder="'Type'" :items="type">
                            <template slot="prepend-item">
                                <div v-if="type.length" class="d-flex">
                                    <v-btn class="mx-auto mb-2" color="primary" @click="selectAll('type',type)">tous</v-btn>
                                </div>
                            </template>
                        </v-select>
                        </v-responsive>
                    </td>
                    <td style="vertical-align:top">
                        <v-responsive
                        class="mx-0"
                        max-width="250"
                        >
                        <v-select chips multiple @change="controlVoir()" v-model="voir.categorie" :placeholder="'Catégorie'" :items="categorie">
                            <template slot="prepend-item">
                                <div v-if="categorie.length" class="d-flex">
                                    <v-btn class="mx-auto mb-2" color="primary" @click="selectAll('categorie',categorie)">tous</v-btn>
                                </div>
                            </template>
                        </v-select>
                        </v-responsive>
                    </td>
                    <td style="vertical-align:top">
                        <v-responsive
                        class="mx-0"
                        max-width="250"
                        >
                        <v-select chips multiple @change="controlVoir()" v-model="voir.salle" :placeholder="'Salle'" :items="salle">
                            <template slot="prepend-item">
                                <div v-if="salle.length" class="d-flex">
                                    <v-btn class="mx-auto mb-2" color="primary" @click="selectAll('salle',salle)">tous</v-btn>
                                </div>
                            </template>
                        </v-select>
                        </v-responsive>
                    </td>
                    <td style="vertical-align:top">
                        <v-responsive
                        class="mx-0"
                        max-width="250"
                        >
                        <v-select chips multiple @change="controlVoir()" v-model="voir.creneau" :placeholder="'Creneau'" :items="creneau">
                            <template slot="prepend-item">
                                <div v-if="creneau.length" class="d-flex">
                                    <v-btn class="mx-auto mb-2" color="primary" @click="selectAll('creneau',creneau)">tous</v-btn>
                                </div>
                            </template>
                        </v-select>
                        </v-responsive>
                    </td>
                    <td style="vertical-align:top">
                        <v-responsive
                        class="mx-0"
                        max-width="250"
                        >
                        <v-select chips multiple @change="controlVoir()" v-model="voir.statut" :placeholder="'Statut'" :items="statut">
                            <template slot="prepend-item">
                                <div v-if="statut.length" class="d-flex">
                                    <v-btn class="mx-auto mb-2" color="primary" @click="selectAll('statut',statut)">tous</v-btn>
                                </div>
                            </template>
                        </v-select>
                        </v-responsive>
                    </td>
                    <td style="vertical-align:top">
                        <span class="d-flex flex-column">
                            <v-btn @click="appliquerFiltre()" :disabled="voir.disabledFiltre" class="mx-auto mt-4" small  color="green">Appliquer</v-btn>
                            <v-btn @click="initFilter()" class="mx-auto my-2" small  color="grey">Initialiser</v-btn>
                        </span>
                    </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-simple-table fixed-header height="400" v-if="membre.length">
                <thead>
                    <tr>
                    <th class="primary--text"> 
                        ID
                    </th>
                    <th class="primary--text"> 
                        référence
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="saison" class="">Saison</label>
                            <v-checkbox
                            id="saison"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="saison"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="type" class="">Type</label>
                            <v-checkbox
                            id="type"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="type"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="titre" class="">Titre</label>
                            <v-checkbox
                            id="titre"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="titre"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="nom" class="">Nom</label>
                            <v-checkbox
                            id="nom"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="nom"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="prenom" class="">Prénom</label>
                            <v-checkbox
                            id="prenom"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="prenom"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="naissance" class="">Naissance</label>
                            <v-checkbox
                            id="naissance"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="naissance"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label @click="voir.contact.show=!voir.contact.show,voir.contact.keyLine=null" class="">Contact</label>
                            <v-checkbox
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="contact"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>

                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label @click="voir.responsable.show=!voir.responsable.show,voir.responsable.keyLine=null" class="">Responsable</label>
                            <v-checkbox
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="responsable"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label @click="voir.contactUrgence.show=!voir.contactUrgence.show" class="">Urgence</label>
                            <v-checkbox
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="contactUrgence"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label @click="voir.affiliation.show=!voir.affiliation.show,voir.affiliation.keyLine=null" class="">Séances</label>
                            <v-checkbox
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="affiliation"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label @click="voir.optin.show=!voir.optin.show,voir.optin.keyLine=null" class="">Opt in</label>
                            <v-checkbox
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="optin"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="certificat" class="">Certificat</label>
                            <v-checkbox
                            id="certificat"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="certificat"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="prix" class="">Prix</label>
                            <v-checkbox
                            id="prix"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="prix"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="statut" class="">Statut</label>
                            <v-checkbox
                            id="statut"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="statut"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text text-no-wrap">
                        <v-layout class="d-flex align-center">
                            <label for="mode" class="">Mode</label>
                            <v-checkbox
                            id="mode"
                            color="primary"
                            class="justify-start custom-checkbox pa-0 ma-0"
                            v-model="selectedExportKey"
                            value="mode"
                            @change="controlSelect()"
                            :ripple="false"
                            hide-details
                            >
                            </v-checkbox>
                        </v-layout>
                    </th>
                    <th class="primary--text">Select</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in membre" :key="index" @dblclick.stop="vue='modifier',typeForm='',assignUpdate(item), itemUpdate=item">
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{index+1}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{item.ref_cotisation}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{saison.find(el=>el.value==item.ref_inscription).text}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{type.find(el=>el.value==item.type).text}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{titre.find(el=>el.value==item.information.titre).text}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{item.information.nom}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{item.information.prenom}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{item.information.naissance}}</td>

                        <td style="vertical-align:top">
                            <v-simple-table v-if="(voir.contact.show && index == voir.contact.keyLine)||(voir.contact.show && voir.contact.keyLine == null)">
                                <thead>
                                    <tr>
                                    <th class="grey--text text-no-wrap">Adresse</th>
                                    <th class="grey--text text-no-wrap">CP</th>
                                    <th class="grey--text text-no-wrap">Ville</th>
                                    <th class="grey--text text-no-wrap">Email</th>
                                    <th class="grey--text text-no-wrap">Tel.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr @dblclick.stop="vue='modifier',typeForm='contact',assignUpdate({...item.contact,ref_licencie:item.ref_licencie}), itemUpdate=item.contact"> 
                                        <td class="text-no-wrap">{{item.contact.adresse}}</td>
                                        <td class="text-no-wrap">{{item.contact.cp}}</td>
                                        <td class="text-no-wrap">{{item.contact.ville}}</td>
                                        <td class="text-no-wrap">{{item.contact.email}}</td>
                                        <td class="text-no-wrap">{{item.contact.tel_mobile}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <v-icon v-else @click="voir.contact.keyLine = index,voir.contact.show=true" size="32">mdi-eye</v-icon>
                        </td>

                        <td style="vertical-align:top">
                            <v-simple-table v-if="(voir.responsable.show && index == voir.responsable.keyLine)||(voir.responsable.show && voir.responsable.keyLine == null) && item.type==1">
                                <thead>
                                    <tr>
                                    <th class="grey--text text-no-wrap">Titre</th>
                                    <th class="grey--text text-no-wrap">Nom</th>
                                    <th class="grey--text text-no-wrap">Prénom</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr @dblclick.stop="vue='modifier',typeForm='responsable',assignUpdate({...item.responsable,ref_licencie:item.ref_licencie}), itemUpdate=item.responsable">
                                        <td class="text-no-wrap">{{titre.find(el=>el.value==item.responsable.titre).text}}</td>
                                        <td class="text-no-wrap">{{item.responsable.nom}}</td>
                                        <td class="text-no-wrap">{{item.responsable.prenom}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <v-icon v-else-if="item.type==1" @click="voir.responsable.keyLine = index,voir.responsable.show=true" size="32">mdi-eye</v-icon>
                        </td>
                        <td style="vertical-align:top">
                            <v-simple-table v-if="(voir.contactUrgence.show && index == voir.contactUrgence.keyLine)||(voir.contactUrgence.show && voir.contactUrgence.keyLine == null) && item.type==2">
                                <thead>
                                    <tr>
                                    <th class="grey--text text-no-wrap">Titre</th>
                                    <th class="grey--text text-no-wrap">Nom</th>
                                    <th class="grey--text text-no-wrap">Prémon</th>
                                    <th class="grey--text text-no-wrap">Tel.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr @dblclick.stop="vue='modifier',typeForm='urgence',assignUpdate({...item.urgence}), itemUpdate=item.urgence">
                                        <td class="text-no-wrap">{{titre.find(el=>el.value==item.urgence.titre).text}}</td>
                                        <td class="text-no-wrap">{{item.urgence.nom}}</td>
                                        <td class="text-no-wrap">{{item.urgence.prenom}}</td>
                                        <td class="text-no-wrap">{{item.urgence.tel_mobile}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <v-icon v-else-if="item.type==2" @click="voir.contactUrgence.keyLine = index,voir.contactUrgence.show=true" size="32">mdi-eye</v-icon>
                        </td>
                        <td style="vertical-align:top">
                            <v-simple-table v-if="(voir.affiliation.show && index == voir.affiliation.keyLine)||(voir.affiliation.show && voir.affiliation.keyLine == null)">
                                <thead>
                                    <tr>
                                    <th class="grey--text text-no-wrap">Catégorie</th>
                                    <th class="grey--text text-no-wrap">Salle</th>
                                    <th class="grey--text text-no-wrap">Créneau</th>
                                    <th class="grey--text text-no-wrap"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(value,i) in item.affiliation" :key="i">
                                        <td class="text-no-wrap text-capitalize">{{value.categorieLibele}}</td>
                                        <td class="text-no-wrap text-capitalize">{{value.salleLibele}}</td>
                                        <td class="text-no-wrap text-capitalize">{{value.creneauLibele}}</td>
                                        <td class="text-no-wrap text-capitalize">{{value.choix && !value.valide?`choix ${value.choix}`:""}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="text-center">
                                            <v-btn v-if="!item.affiliation[0].valide" @click="validerAffiliation(item.affiliation,item.ref_licencie)" small>Valider</v-btn>
                                            <v-btn @click="vue='modifier',typeForm='affiliation',groupAffiliationByCategorie(item.affiliation,['categorieRef'],{saison:item.ref_inscription,type:item.type}),update.ref=item.ref_licencie,update.type=item.type" small class="ml-4">Modifier</v-btn>
                                            <v-btn v-if="item.affiliation[0].valide" @click="vue='ajouter',typeForm='affiliation',assingAdd({saison:item.ref_inscription,type:item.type,ref:item.ref_licencie})" small class="ml-4">Ajouter</v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <v-icon v-else @click="voir.affiliation.keyLine = index,voir.affiliation.show=true" size="32">mdi-eye</v-icon>
                        </td>
                        <td style="vertical-align:top">
                            <v-simple-table v-if="(voir.optin.show && index == voir.optin.keyLine)||(voir.optin.show && voir.optin.keyLine == null)">
                                <thead>
                                    <tr>
                                    <th class="grey--text text-no-wrap">Image</th>
                                    <th class="grey--text text-no-wrap">Réglement</th>
                                    <th class="grey--text text-no-wrap">Licence</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr @dblclick="vue='modifier',typeForm='optin',assignUpdate(item.optin), itemUpdate=item.optin">
                                        <td class="text-no-wrap">{{item.optin.droit_image?"Oui":"Non"}}</td>
                                        <td class="text-no-wrap">{{item.optin.reglement_club?"Oui":"Non"}}</td>
                                        <td class="text-no-wrap">{{item.optin.licence_fftda?"Oui":"Non"}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <v-icon v-else @click="voir.optin.keyLine = index,voir.optin.show=true" size="32">mdi-eye</v-icon>
                        </td>
                        <td style="vertical-align:top" class="pt-2">
                            <a :id="'certificat'+index" v-if="item.pieceJointe.piece!='' && item.pieceJointe.type==1" style="cursor:pointer" :href="downloadCertificat(item.pieceJointe.piece)" target="_blank" :download="item.pieceJointe.piece"><v-icon size="25" >mdi-attachment</v-icon></a>
                            <v-icon v-else size="25">mdi-attachment-off</v-icon>
                        </td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{item.montant_cotisation}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{statut.find(el=>el.value==item.prix.statut).text}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">{{!Array.isArray(JSON.parse(item.prix.select_mode)) ?  mode_paiement.find(el=>el.value==item.prix.select_mode).text : JSON.parse(item.prix.select_mode).length + " moyens"}}</td>
                        <td style="vertical-align:top" class="pt-2 text-no-wrap">
                            <v-checkbox
                            :key="item.ref_licencie"
                            class="pt-0 mt-0"
                            v-model="selected"
                            :value="item.ref_licencie"
                            ></v-checkbox>
                        </td>
                    </tr>
                </tbody>
                </v-simple-table>
                <!-- <div class="text-center mt-4">
                    <v-pagination
                    v-model="voir.page"
                    :length="6"
                    :total-visible="0"
                    ></v-pagination>
                </div> -->
            </div>
            <div v-if="vue=='modifier' && typeForm==''">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">Saison</th>
                            <th class="primary--text">Type</th>
                            <th class="primary--text">Titre</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="150"
                                >
                                    <v-select readonly @change="controlSave()" v-model="update.saison" :placeholder="'Saison'" :items="saison"></v-select>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="150"
                                >
                                <v-select readonly @change="controlSave()" v-model="update.type" :placeholder="'Type'" :items="type"></v-select>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="150"
                                >
                                <v-select readonly @change="controlSave()" v-model="update.titre" :placeholder="'Titre'" :items="titre"></v-select>
                                </v-responsive>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">Nom</th>
                            <th class="primary--text">Prénom</th>
                            <th class="primary--text">Naissance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.nom" placeholder="Nom"></v-text-field>
                            </td>
                            <td>
                                <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.prenom" placeholder="Prénom"></v-text-field>
                            </td>
                            <td>
                                <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.naissance" placeholder="10/06/1990"></v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">Prix</th>
                            <th class="primary--text">Statut</th>
                            <th class="primary--text">Mode</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td>
                            <v-responsive
                            class="mx-0"
                            max-width="150"
                            >
                            <v-text-field @keyup="controlSave()" @change="controlSave()" v-model="update.prix" placeholder="Prix"></v-text-field>
                            </v-responsive>
                        </td>
                        <td>
                            <v-responsive
                            class="mx-0"
                            max-width="150"
                            >
                            <v-select @change="controlSave()" v-model="update.statut" :placeholder="'Statut'" :items="statut"></v-select>
                            </v-responsive>
                        </td>
                        <td>
                            <v-responsive
                            class="mx-0"
                            max-width="150"
                            >
                            <v-select @change="controlSave()" v-model="update.mode" multiple :placeholder="'Mode'" :items="mode_paiement"></v-select>
                            </v-responsive>
                        </td>
                    </tbody>
                </v-simple-table>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="3" class="primary--text">Remarque</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td colspan="3">
                            <v-textarea
                            filled
                            auto-grow
                            label="Remarque"
                            rows="3"
                            row-height="30"
                            v-model="update.remarque"
                            @keyup="controlSave()"
                            ></v-textarea>
                        </td>
                    </tbody>
                </v-simple-table>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="3" class="primary--text">Documents</th>
                        </tr>
                    </thead>
                    <tbody>
                        <td colspan="1">
                          <v-switch
                  v-model="update.valid_certif"
                  @change="controlSave()"
                  label="Certificat médical"
                  hide-details
                  inset
                  class="mt-0 ml-2 pt-0"
                ></v-switch>
                        </td>
                    </tbody>
                </v-simple-table>
            </div>
            <div v-if="vue=='modifier' && typeForm=='contact'">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">Adresse</th>
                            <th class="primary--text">CP</th>
                            <th class="primary--text">Ville</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.adresse" placeholder="Adresse"></v-text-field>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.cp" placeholder="Code postal"></v-text-field>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.ville" placeholder="Ville"></v-text-field>
                                </v-responsive>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">Email</th>
                            <th class="primary--text">Tel.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.email" placeholder="Email"></v-text-field>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.tel" placeholder="Tel."></v-text-field>
                                </v-responsive>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
            <div v-if="vue=='modifier' && typeForm=='responsable'">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">titre</th>
                            <th class="primary--text">nom</th>
                            <th class="primary--text">prenom</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-select @change="controlSave()" v-model="update.titre" :placeholder="'Titre'" :items="titre"></v-select>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.nom" placeholder="Nom"></v-text-field>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.prenom" placeholder="Prénom"></v-text-field>
                                </v-responsive>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
            <div v-if="vue=='modifier' && typeForm=='urgence'">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">Titre</th>
                            <th class="primary--text">Nom</th>
                            <th class="primary--text">Prenom</th>
                            <th class="primary--text">Tel.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-select @change="controlSave()" v-model="update.titre" :placeholder="'Titre'" :items="titre"></v-select>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.nom" placeholder="Nom"></v-text-field>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.prenom" placeholder="Prénom"></v-text-field>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-text-field @change="controlSave()" @keyup="controlSave()" v-model="update.tel" placeholder="Tel."></v-text-field>
                                </v-responsive>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
            <div v-if="vue=='modifier' && typeForm=='affiliation'">
                <v-simple-table>
                    <thead>
                        <tr>
                        <th class="primary--text">Saison</th>
                        <th class="primary--text">Type</th>
                        <th class="primary--text">Categorie</th>
                        <th class="primary--text">Salle</th>
                        <th class="primary--text">Créneau</th>
                        <th class="primary--text"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(value,key) in groupAffiliation" :key="key">
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="150"
                                >
                                    <v-select readonly v-model="update.saison[Object.keys(value)[0]]" :placeholder="'Saison'" :items="saison">
                                    </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="150"
                                >
                                    <v-select readonly v-model="update.typeAffiliation[Object.keys(value)[0]]" :placeholder="'Type'" :items="type">
                                    </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="250"
                                >
                                    <v-select @change="controlSave()" v-model="update.categorie[Object.keys(value)[0]]" :placeholder="'Catégorie'" :items="update.categorieSelect[Object.keys(value)[0]]">
                                    </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="250"
                                >
                                    <v-select  @change="controlSave()" chips multiple v-model="update.salle[Object.keys(value)[0]]" :placeholder="'Salle'" :items="update.salleSelect[Object.keys(value)[0]]">
                                    </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="250"
                                >
                                <v-select  chips multiple @change="controlSave()" v-model="update.creneau[update.categorie[Object.keys(value)[0]]]" :placeholder="'Creneau'" :items="update.creneauSelect[Object.keys(value)[0]]">
                                <!-- <v-select  chips multiple @change="controlSave()" v-model="update.creneau[Object.keys(value)[0]]" :placeholder="'Creneau'" :items="update.creneauSelect[Object.keys(value)[0]]"> -->
                                <!-- <v-select  :clearable="!update.readonly[Object.keys(value)[0]]" chips multiple @change="controlSave()" v-model="update.creneau[Object.keys(value)[0]]" :placeholder="'Creneau'" :items="update.creneauSelect[Object.keys(value)[0]]"> -->
                                <!-- <v-select :readonly="!value[Object.keys(value)[0]].choix?true:false" :clearable="value[Object.keys(value)[0]].choix?true:false" chips multiple @change="controlVoir()" v-model="update.creneau[Object.keys(value)[0]]" :placeholder="'Creneau'" :items="update.creneauSelect[Object.keys(value)[0]]"> -->
                                    <template slot="prepend-item">
                                        <div v-if="update.creneauSelect[Object.keys(value)[0]].length" class="d-flex">
                                            <v-btn class="mx-auto mb-2" color="primary" @click="selectAllCrenneau('creneau',`${Object.keys(value)[0]}`,update.creneauSelect[Object.keys(value)[0]]),controlSave()">tous</v-btn>
                                        </div>
                                    </template>
                                </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-checkbox
                                :key="key"
                                v-if="groupAffiliation.length>1"
                                v-model="update.selected"
                                :value="Object.keys(value)[0]"
                                color="primary"
                                class="justify-start custom-checkbox pa-0"
                                @change="controlSelect()">
                                </v-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
            <div v-if="vue=='ajouter' && typeForm=='affiliation'">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">Saison</th>
                            <th class="primary--text">Type</th>
                            <th class="primary--text">Categorie</th>
                            <th class="primary--text">Salle</th>
                            <th class="primary--text">Créneau</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="150"
                                >
                                    <v-select readonly v-model="add.saison" :placeholder="'Saison'" :items="saison">
                                    </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="150"
                                >
                                    <v-select readonly v-model="add.type" :placeholder="'Type'" :items="type">
                                    </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="250"
                                >
                                    <!-- <v-select @change="controlAdd()" v-model="add.categorie" :placeholder="'Catégorie'" :items="add.categorieSelect"> -->
                                    <v-select @change="controlAdd(),add.salle=add.preSelectSalle.length?add.preSelectSalle:[],add.creneau=add.preSelectCreneau.length?add.preSelectCreneau:[]" v-model="add.categorie" :placeholder="'Catégorie'" :items="add.categorieSelect">
                                    </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="250"
                                >
                                    <v-select :readonly="add.readonly" @change="controlAdd()" chips multiple v-model="add.salle" :placeholder="'Salle'" :items="add.salleSelect">
                                    </v-select>
                                </v-responsive>
                            </td>
                            <td style="vertical-align:top">
                                <v-responsive
                                class="mx-0"
                                max-width="250"
                                >
                                <v-select :readonly="add.readonly" :clearable="!add.readonly" chips multiple  v-model="add.creneau" :placeholder="'Creneau'" :items="add.creneauSelect">
                                <!-- <v-select :readonly="!value[Object.keys(value)[0]].choix?true:false" :clearable="value[Object.keys(value)[0]].choix?true:false" chips multiple @change="controlVoir()" v-model="update.creneau[Object.keys(value)[0]]" :placeholder="'Creneau'" :items="update.creneauSelect[Object.keys(value)[0]]"> -->
                                    <template slot="prepend-item">
                                        <div v-if="add.creneauSelect.length" class="d-flex">
                                            <v-btn class="mx-auto mb-2" color="primary" @click="selectAllCrenneau('creneau','',add.creneauSelect),controlSave()">tous</v-btn>
                                        </div>
                                    </template>
                                </v-select>
                                </v-responsive>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
            <div v-if="vue=='modifier' && typeForm=='optin'">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="primary--text">Droit à l'image</th>
                            <th class="primary--text">Atteste avoir pris connaissance du réglement</th>
                            <th class="primary--text">S'engage à prendre sa licence au prés de la FFTDA</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-checkbox
                                    class="ml-4"
                                    @change="controlSave()"
                                    color="primary"
                                    v-model="update.droitImage"
                                    :label="`${update.droitImage?'Oui':'Non'}`"
                                    >
                                    </v-checkbox>                                
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-checkbox
                                    class="ml-4"
                                    @change="controlSave()"
                                    color="primary"
                                    v-model="update.reglement"
                                    :label="`${update.reglement?'Oui':'Non'}`"
                                    >
                                    </v-checkbox>
                                </v-responsive>
                            </td>
                            <td>
                                <v-responsive
                                class="mx-0"
                                max-width="100%"
                                >
                                    <v-checkbox
                                    class="ml-4"
                                    @change="controlSave()"
                                    color="primary"
                                    v-model="update.licence"
                                    :label="`${update.licence?'Oui':'Non'}`"
                                    >
                                    </v-checkbox>
                                </v-responsive>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </div>            
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import config from '../../../../config/config.js'
import Inscriptions from "@/services/Inscription.js"
import Categories from "@/services/Categorie.js"
import Horaires from "@/services/Horaire.js"
import Horaire_days from "@/services/Horaire_day.js"
import Membres from "@/services/Membre.js"
// import Salles from "@/services/Salle.js"

export default {
  name: "Members",
  data: () => ({
    // Dialog
    elSendMail:[],
    sendMail:0,
    dialogMail: false,
    typeMail:[{text:'',value:0},{text:'Confirmation',value:1},{text:'Facture',value:2}],
    // Dialog
    // Membre
    titre:[{text:'Madame',value:1},{text:'Monsieur',value:2},{text:'Fille',value:3},{text:'Garçon',value:4}],
    saison:[],
    categorie:[],
    salle:[],
    creneau:[],
    type:[{text:'Enfant',value:1},{text:'Adulte',value:2}],
    mode_paiement:[{text:'En attente',value:1},{text:'Espèces',value:2},{text:'Chèques de banque',value:3},{text:'Chèques ANCV',value:4},{text:'Coupon sport',value:5},{text:'CB',value:6},{text:'Pass sport',value:7}],
    statut:[{text:'En attente',value:1},{text:'Payé',value:2},{text:'Partiel',value:3},{text:'Echec',value:4},{text:'Non payé',value:5}],
    selected:[],
    groupAffiliation:{},
    membreExport:[],
    membre:[],
    // Membre

    selected:[],
    selectedExportKey:[],
    trash:{
      save:false
    },    
    
    itemUpdate:{},

    add:{
        save:false,
        ref:"",
        saison:"",
        type:"",
        categorie:"",
        salle:[],
        creneau:[],
        
        categorieSelect:[],
        salleSelect:[],
        creneauSelect:[],

        preSelectCreneau:[],
        preSelectSalle:[],
        readonly:false,
    },

    update:
    {
      save:false,
      selected:[],
      // info
      ref:"",
      saison:"",
      type:"",
      titre:"",
      nom:"",
      prenom:"",
      naissance:"",
      prix:"",
      statut:"",
      mode:[],
      remarque:"",
      valid_certif:false,

      // contact
      ref_contact:"",
      adresse:"",
      cp:"",
      ville:"",
      email:"",
      tel:"",

      //responsable
      ref_responsable:"",

      //urgence
      ref_contactUrgence:"",

      // optin
      ref_optin:"",
      droitImage:"",
      reglement:"",
      licence:"",

      //affiliation
      saison:{},
      typeAffiliation:{},
      categorie:{},
      salle:{},
      creneau:{},
      readonly:{},

      categorieSelect:{},
      salleSelect:{},
      creneauSelect:{},
    },

    voir:{
      disabledFiltre:false,
      page:null,
      responsable:{
        show:false,
        keyLine:null,
      },
      contact:{
        show:false,
        keyLine:null,
      },
      contactUrgence:{
        show:false,
        keyLine:null,
      },
      affiliation:{
        show:false,
        keyLine:null,
      },
      optin:{
        show:false,
        keyLine:null,
      },
      ref:"",
      nom:"",
      saison:[],
      type:[],
      categorie:[],
      salle:[],
      creneau:[],
      statut:[],
    },
    vue: "voir",
    typeForm:"",
  }),
  mounted(){
    this.getSaison()
  },

  watch: {
    'add.creneau': {
      handler: async function (val) {
        console.log('a thing changed',val)
        if(this.vue=="ajouter" && this.typeForm=="affiliation"){

            if(val.length){
                this.add.save=true
            }else{
                this.add.save=false
            }
        }
        // if(val){
        //     let options = {ref_inscription:this.add.saison, type:this.add.type,categorie:this.add.categorie,salle:this.add.salle}

        //     this.add.salleSelect = await this.getSalleByArraySaisonTypeCategorie(options)
            
        //     let optionsSelect = {ref_inscription:this.add.saison, type:this.add.type,categorie:this.add.categorie,salle:this.add.salleSelect.map(el=>el.value)}
            
        //     this.add.creneauSelect = await this.getCreneauByArraySaisonTypeCategorieSalle(optionsSelect)
        // }
      },
      deep: true
    }
  },
  computed:{
     
     jsonFields(){
     
     let obj =   {
        "Référence":{
        field: "ref_cotisation",
        type: "string",
          callback: (value) => {
            return `\u00A0${value}`;
          },
        // valueSelect:"reference"
        },
        "Saison":{
        field: "ref_inscription",
        callback: (value) => {return this.saison.find(el=>el.value==value).text},
        valueSelect:"saison"
        },
        "Type":{
            field: "type",
            callback: (value) => {
                return this.type.find(el=>el.value==value).text},
            valueSelect:"type"
        },
        "Titre":{
            field: "information.titre",
            callback: (value) => {return this.titre.find(el=>el.value==value).text},
            valueSelect:"titre"
        },
        "Nom":{
            field: "information.nom",
            valueSelect:"nom"
        },
        "Prénom":{
            field: "information.prenom",
            valueSelect:"prenom"
        },
        "Naissance":{
            field: "information.naissance",
            valueSelect:"naissance"
        },
        // "Contact":{
        //     field:"contact",
        //     callback: (value) => {return `${value.adresse} \n ${value.cp} ${value.ville} \n ${value.email} \n ${value.tel_mobile}`},
        // },
        "Adresse":{
            field: "contact.adresse",
            valueSelect:"contact"
        },
        "CP":{
            field: "contact.cp",
            valueSelect:"contact"
        },
        "Ville":{
            field: "contact.ville",
            valueSelect:"contact"
        },
        "E-mail":{
            field: "contact.email",
            valueSelect:"contact"
        },
        "Tel.":{
            field: "contact.tel_mobile",
            callback: (value) => {
            return `\u00A0${value}`;
          },
          type: "string",
            valueSelect:"contact"
        },
        // "Responsable":{
        //     field: "responsable",
        //     callback: (value) => {return value.titre?`${value.titre?this.titre.find(el=>el.value==value.titre).text:""} \n ${value.nom} \n ${value.prenom}`:""},
        // },
        "Titre responsable":{
            field: "responsable.titre",
            callback: (value) => {return value?this.titre.find(el=>el.value==value).text:""},
            valueSelect:"responsable"
       },
        "Nom responsable":{
            field: "responsable.nom",
            valueSelect:"responsable"
        },
        "Prénom responsable":{
            field: "responsable.prenom",
            valueSelect:"responsable"
        },
        // "Contact urgence":{
        //     field:"urgence",
        //     callback: (value) => {return value.titre?`${value.titre?this.titre.find(el=>el.value==value.titre).text:""} \n ${value.nom} \n ${value.prenom} \n ${value.tel_mobile}`:""}
        // },
        "Titre urgence":{
            field: "urgence.titre",
            callback: (value) => {return value?this.titre.find(el=>el.value==value).text:""},
            valueSelect:"urgence"
       },
       "Nom urgence":{
            field: "urgence.nom",
            valueSelect:"urgence"
        },
        "Prénom urgence":{
            field: "urgence.prenom",
            valueSelect:"urgence"
        },
        "tel. urgence":{
            field: "urgence.tel_mobile",
            valueSelect:"urgence",
            callback: (value) => {
            return `\u00A0${value}`;
          },
        },
        "Séances":{
            field: "affiliation",
            callback: (value) => {
                    let affiliation = ""
                    value.forEach(el=>{
                       affiliation +=`${el.categorieLibele} : ${el.salleLibele} : ${el.creneauLibele}${!el.valide?' ---> Choix '+el.choix:''}\n`
                    })
                    return affiliation                
                },
            valueSelect:"affiliation"
        },
        // "Optin":{
        //     field: "optin",
        //     callback:(value) => {return `Droit image: ${value.droit_image?'Oui':'Non'} \n Réglement du club: ${value.reglement_club?'Oui':'Non'} \n Licence FFTDA: ${value.licence_fftda?'Oui':'Non'}`
        //     },
        // },
        "Droit image":{
            field: "optin.droit_image",
            callback: (value) => {return value?"Oui":"Non"},
            valueSelect:"optin"
       },
        "Réglement club":{
            field: "optin.reglement_club",
            callback: (value) => {return value?"Oui":"Non"},
            valueSelect:"optin"
      },
        "Licence FFTDA":{
            field: "optin.licence_fftda",
            callback: (value) => {return value?"Oui":"Non"},
            valueSelect:"optin"
       },
        "Certificat":{
            field: "pieceJointe",
            callback: (value) => {return value.type==1 && (value.piece!=="" || value.valid)?"Oui":"Non"},
            valueSelect:"certificat"
       },
    //     "Certificat vérifié":{
    //         field: "pieceJointe",
    //         callback: (value) => {return value.type==1 && value.valid?"Oui":"Non"},
    //    },
        "Prix":{
            field: "montant_cotisation",
            valueSelect:"prix"
       },
        "Statut":{
            field: "prix.statut",
            callback: (value) => {return this.statut.find(el=>el.value==value).text},
            valueSelect:"statut"
       },
        "Mode":{
            field: "prix.select_mode",
            callback: (value) => {
                let moyens = ""
                if(Array.isArray(JSON.parse(value))){
                    JSON.parse(value).forEach(moyen=>{  
                moyens +=
                `${this.mode_paiement.find(el=>el.value==moyen).text}\n`})
                }
               
                return !Array.isArray(JSON.parse(value)) ?  this.mode_paiement.find(el=>el.value==value).text : moyens},
            // callback: (value) => {return this.mode_paiement.find(el=>el.value==value).text},
            valueSelect:"mode"
       },
        "Remarque":{
            field: "information.remarque",
       },
    }
    // selectedExportKey
    let objFilter = {"Référence":{
        field: "ref_cotisation",
        type: "string",
          callback: (value) => {
            return `\u00A0${value}`;
          },
        },}
    // Object.keys(obj).filter((el)=>{
    Object.keys(obj).map((el,index)=>{
        // console.log("el",obj[el].valueSelect)
        if(this.selectedExportKey.includes(obj[el].valueSelect)){return objFilter[el]=obj[el]}
        // this.selectedExportKey.includes(obj[el].valueSelect)
    })
    console.log("selectedExportKey",this.selectedExportKey)
    console.log("objFilter",objFilter)
    return this.selectedExportKey.length?objFilter:obj
    },
  },
  methods:{
    groupByCotisation(array){
        let elSendMail  = array.filter(el=>this.selected.includes(el.ref_licencie))
        elSendMail = elSendMail.reduce((acc, item) => {
                const key = item.ref_cotisation;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});
            return elSendMail
    },

    async send(){
        if(this.sendMail==1 && this.selected.length){
            console.log("this.selected",this.selected)
            this.elSendMail = this.membre.filter(el=>this.selected.includes(el.ref_licencie))
            console.log("this.elSendMail",this.elSendMail)
           const responseSend = await Membres.resendMailConfirmation({elSendMail:this.elSendMail})   
           if(responseSend){
            this.elSendMail = []
            this.dialogMail = false
            this.sendMail = 0
           }
        }

        if(this.sendMail==2 && this.selected.length){
            // this.elSendMail  = this.membre.filter(el=>this.selected.includes(el.ref_licencie))
            // this.elSendMail = this.elSendMail.reduce((acc, item) => {
            //     const key = item.ref_cotisation;
            //     if (!acc[key]) {
            //         acc[key] = [];
            //     }
            //     acc[key].push(item);
            //     return acc;
            // }, {});
            this.elSendMail = this.groupByCotisation(this.membre)
            console.log("this.elSendMail===>",this.elSendMail)

           const responseSend = await Membres.sendMailFacture({elSendMail:this.elSendMail})   
           if(responseSend){
            this.elSendMail = []
            this.dialogMail = false
            this.sendMail = 0
           }
        }
    },
downloadCertificat (fileName) {
    return `${config.api_url}/data/uploads/certificats/${fileName}`
},
//    downloadCertificat (fileName,label) {
//     this.$axios.get(`${config.api_url}/public/data/uploads/certificats/${fileName}`, { responseType: 'blob' })
//       .then(response => {
//         const extSplit = fileName.split(".")
//         const ext = extSplit[extSplit.length-1]
//         const blob = new Blob([response.data], { type:`application/${ext}`})
//         // const blob = new Blob([response.data])
        
//         const link = document.createElement('a')
//         link.href = URL.createObjectURL(blob)
//         link.download = label
//         link.click()
//         URL.revokeObjectURL(link.href)
//       }).catch(console.error)
//     },

   init(){
    this.membre = []
    this.selected = []
    this.update.selected = []
    this.update.save = false
   },
   async supprimer(){
        if(this.selected.length && this.vue=='voir'){
            console.log("this.vue supp",this.vue)
           this.membre = this.membre.filter(el=>!this.selected.includes(el.ref_licencie))
           const deleteMembre = await Membres.putDelete({ref_licencie:this.selected})
           if(deleteMembre){
            this.selected = []
           }
        }
        
        if(this.update.selected.length && this.vue=='modifier' && this.typeForm=='affiliation'){
            console.log("this.update.selected",this.update.selected)
            let creneau = []
            this.groupAffiliation.forEach((el)=>{
                el[Object.keys(el)[0]].map(val=>{
                    // creneau.push(val.creneauRef)
                    if(this.update.selected.includes( Object.keys(el)[0])){
                       creneau.push(val.creneauRef) 
                    }
                })
            })
            console.log("creneau à supprimer",creneau)

            const deleteAffiliation = await Membres.deleteAffiliation({
                ref_licencie:this.update.ref,
                creneau_delete:creneau,
            })

            if(deleteAffiliation){
                this.groupAffiliation =this.groupAffiliation.filter(el=>!this.update.selected.includes( Object.keys(el)[0]))
                this.update.selected = []
            }
        }

        // if(this.update.selected.length && this.vue=='modifier' && this.typeForm=='affiliation'){
        //     console.log("this.update.selected",this.update.selected)
        //     this.groupAffiliation =this.groupAffiliation.filter(el=>!this.update.selected.includes( Object.keys(el)[0]))
        //     let creneau = []
        //     this.groupAffiliation.forEach((el)=>{
        //         let creneauType = []
        //         el[Object.keys(el)[0]].map(val=>{
        //             if(!val.valide && this.update.type==2){
        //                 creneauType.push(val.creneauRef)
        //             }else{
        //                 creneau.push(val.creneauRef)
        //             }
        //         })
        //         if(this.update.type==2){
        //             creneau.push(creneauType)
        //         }
        //     })
        //     console.log("creneau supprimer",creneau)
        //     this.update.selected = []
        // }
        
    },
    membreDataSelect(){
        if(this.vue=='voir'){
            console.log("this.vue membre",this.vue)
            if(this.selected.length){
                this.membreExport = this.membre.filter(el=>this.selected.includes(el.ref_licencie))
            } else {
                this.membreExport = this.membre
            }
        }
     },
    initFilter(){
        this.voir.saison=[]
        this.voir.type=[]
        this.voir.categorie=[]
        this.voir.salle=[]
        this.voir.creneau=[]
        this.voir.statut=[]
        this.selected = []
        this.membre = []
    },
    async appliquerFiltre(){
        if(this.voir.saison.length){
            try{
                this.voir.disabledFiltre = true
                let member =   await Membres.getSearch(
                    {
                        ref_inscription:this.voir.saison,
                        type:this.voir.type,
                        categorie:this.voir.categorie,
                        salle:this.voir.salle,
                        creneaux:this.voir.creneau,
                        statut:this.voir.statut,
                    }
                )

                if(member){
                    this.selected = []
                    this.membre = []
                    this.membre = member.reverse()
                    console.log("this.membre",this.membre)
                }
            }
            catch(err){
                console.log("Query error")
            } finally{
                this.voir.disabledFiltre = false
            }
            
        }
    },
    async assingAdd(item){
        this.add.ref = item.ref
        this.add.saison = item.saison
        this.add.type = item.type
        console.log("add",this.add)

        let options = {ref_inscription:this.add.saison, type:this.add.type,categorie:this.add.categorie,salle:this.add.salle}
        this.add.categorieSelect = await this.getCategorieByArrayTypeInscription(options)
        this.add.salleSelect = await this.getSalleByArraySaisonTypeCategorie(options)
        this.add.creneauSelect = await this.getCreneauByArraySaisonTypeCategorieSalle(options)
    },

    isEqual(tableau1, tableau2) {

        if(tableau1!=undefined && tableau2!=undefined){
            if (tableau1.length !== tableau2.length) return false

            let matching = tableau1.filter(val => tableau2.includes(val))

            if (matching.length == tableau1.length) return true
        }

        return false
    },

    async groupAffiliationByCategorie(arr, keys, value){

        // https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d

        let groupBy = arr.reduce((storage, item) => {
            const objKey = keys.map(key => `${ item[key] }`).join(':');
            if (storage[objKey]) {
                // console.log("item if",item)
            storage[objKey].push(item);
            } else {
                // console.log("item else",item)
                console.log("objKey",objKey)
            storage[objKey] = [item];
            }
            return storage;
        }, {});
        // }, []);

        let groupByArray = Object.keys(groupBy).map(el=>{
            let obj ={}
            obj[el] = groupBy[el]
            return obj
        }
        )

        console.log("groupByArray",groupByArray)
        console.log("saison==>",value.saison)

        // Set itemUpdate
        this.itemUpdate["saison"]={}
        this.itemUpdate["type"]={}
        this.itemUpdate["categorie"]={}
        this.itemUpdate["salle"]={}
        this.itemUpdate["creneau"]={}   
        
        console.log("groupBy",groupBy)

        this.update.saison={}
        this.update.typeAffiliation={}
        this.update.categorie={}
        this.update.salle={}
        this.update.creneau={}
        this.update.readonly={}

        // this.update.categorieSelect=[]
        // this.update.salleSelect=[]
        // this.update.creneauSelect=[]

        for(let index=0;index < Object.keys(groupBy).length;index++){
            let val = Object.keys(groupBy)[index]

            this.$set(this.update.saison, val, value.saison)
            this.$set(this.update.typeAffiliation, val, value.type)
            this.$set(this.update.categorie, val, val.split(":")[0])
            this.$set(this.update.readonly, val, !groupByArray[index][val][0].choix?true:false)
            
            let arraySalleRef = [] 
            groupByArray[index][val].map(el=>{if(!arraySalleRef.includes(el.salleRef)){arraySalleRef.push(el.salleRef)}})
            this.$set(this.update.salle, val, arraySalleRef)
            

            let arrayCreneauRef = groupByArray[index][val].map(el=>el.creneauRef)
            console.log("val",val)
            this.$set(this.update.creneau, val, arrayCreneauRef)

        
            let options = {ref_inscription:this.update.saison[val], type:this.update.typeAffiliation[val],categorie:this.update.categorie[val],salle:this.update.salle[val]}
            
            this.$set(this.update.categorieSelect, val, await this.getCategorieByArrayTypeInscription(options))
            this.$set(this.update.salleSelect, val, await this.getSalleByArraySaisonTypeCategorie(options))
            this.$set(this.update.creneauSelect, val, await this.getCreneauByArraySaisonTypeCategorieSalle(options))
            
            this.itemUpdate["saison"][val]=value.saison
            this.itemUpdate["type"][val]=value.type
            this.itemUpdate["categorie"][val]=val
            // this.itemUpdate["categorie"][val]=val.split(":")[0]
            this.itemUpdate["salle"][val]=arraySalleRef
            // this.itemUpdate["salle"][val]=groupByArray[index][val][0].salleRef
            // this.itemUpdate["salle"][val]=val.split(":")[1]
            this.itemUpdate["creneau"][val]=arrayCreneauRef
        } 

        this.groupAffiliation = groupByArray

        console.log("this.update",this.update)
        console.log("this.itemUpdate",this.itemUpdate)

    },

    async validerAffiliation(item,ref){
        let arrayRefCreneauValide = item.map(el=>el.creneauRef)
        console.log("arrayRefCreneauValide",arrayRefCreneauValide)
    
        await Membres.putAffiliation(
            {
                action:"valider",
                ref_licencie:ref,
                creneau_valide:JSON.stringify(arrayRefCreneauValide)
            }
        )

        // changement de la vue this.membre

        item.map(el=>el.valide=true)

    },
    async save(){
        // info
        if(this.vue=='modifier' && this.typeForm=='' && this.update.save){
         const MembreUpdate =   await Membres.put(
            {
                ref: this.update.ref,
                ref_cotisation: this.update.ref_cotisation,
                // saison: this.update.saison,
                // type: this.update.type,
                // titre: this.update.titre,
                nom: this.update.nom,
                prenom: this.update.prenom,
                naissance: this.update.naissance,
                remarque: this.update.remarque,
                prix: this.update.prix,
                statut: this.update.statut,
                mode: JSON.stringify(this.update.mode),
                valid_certif:this.update.valid_certif,
                ref_pj:this.update.ref_pj
            }
        )
        // console.log("this.update save",this.update)
        this.update.save = !MembreUpdate
        }


        // contact
        if(this.vue=='modifier' && this.typeForm=='contact' && this.update.save){
            const MembreUpdateContact =   await Membres.putContact(
            {
                ref: this.update.ref,
                ref_contact: this.update.ref_contact,
                adresse: this.update.adresse,
                cp: this.update.cp,
                ville: this.update.ville,
                email: this.update.email,
                tel_mobile: this.update.tel,
            })
            this.update.save = !MembreUpdateContact
        }
        
        //responsable
        if(this.vue=='modifier' && this.typeForm=='responsable' && this.update.save){
            const MembreUpdateResponsable =   await Membres.putResponsable(
            {
                ref_responsable: this.update.ref_responsable,
                titre: this.update.titre,
                nom: this.update.nom,
                prenom: this.update.prenom,
            })
            this.update.save = !MembreUpdateResponsable
        }
        
        //urgence
        if(this.vue=='modifier' && this.typeForm=='urgence' && this.update.save){
            const MembreUpdateUrgence =   await Membres.putUrgence(
            {
                ref_contactUrgence: this.update.ref_contactUrgence,
                titre: this.update.titre,
                nom: this.update.nom,
                prenom: this.update.prenom,
                tel_mobile: this.update.tel,
            })
            this.update.save = !MembreUpdateUrgence
        }
        
        //optin
        if(this.vue=='modifier' && this.typeForm=='optin' && this.update.save){
            const MembreUpdateOptin =   await Membres.putOptin(
            {
                ref_optin: this.update.ref_optin,
                droit_image: this.update.droitImage,
                reglement_club: this.update.reglement,
                licence_fftda: this.update.licence,
            })
            this.update.save = !MembreUpdateOptin
        }
        
        //affiliation
        if(this.vue=='modifier' && this.typeForm=='affiliation' && this.update.save){
            
            
           let arrayRefCreneauValide = []
           Object.keys(this.update.creneau).map(el=>arrayRefCreneauValide.push(...this.update.creneau[el]))
           console.log("this.update.creneau",this.update.creneau)
           console.log("arrayRefCreneauValide",arrayRefCreneauValide)

            let arrayRefCategorie = []
            Object.keys(this.update.categorie).map(el=>arrayRefCategorie.push(this.update.categorie[el]))
            console.log("this.update.categorie",arrayRefCategorie )

            const MembreUpdateAffiliation =   await Membres.putAffiliation(
            {
                action:"modifier",
                ref_licencie:this.update.ref,
                creneau_valide:JSON.stringify(arrayRefCreneauValide),
                categorie:JSON.stringify(arrayRefCategorie),
                //Pour le calcul du tarif
                creneau:JSON.stringify(this.update.creneau),
                saison:this.update.saison[Object.keys(this.update.saison)[0]],
                type:this.update.type,
                // ref_cotisation:this.update.ref_cotisation,
            }
            )
            this.update.save = !MembreUpdateAffiliation
        }

        if(this.vue=="ajouter" && this.typeForm=="affiliation"){
            const MembreUpdateAddAffiliation =   await Membres.addAffiliation(
            {
                ref_licencie:this.add.ref,
                creneau_ajout: JSON.stringify(this.add.creneau)
            }
            )
            this.add.save = !MembreUpdateAddAffiliation
            this.add.categorie = ""
            this.add.salle = []
            this.add.creneau = []
        }

    },
    async controlSave(){
        console.log("this.update",this.update)
        console.log("this.itemUpdate",this.itemUpdate)
        if(this.vue=="modifier" && this.typeForm==""){
            let mode_prix = this.itemUpdate.prix.select_mode==null ? null : this.itemUpdate.prix.select_mode!=null && !Array.isArray(JSON.parse(this.itemUpdate.prix.select_mode)) ?[parseInt(this.itemUpdate.prix.select_mode)]:JSON.parse(this.itemUpdate.prix.select_mode)
            if(
            // (this.itemUpdate.titre!=undefined && this.update.titre!=this.itemUpdate.information.titre && this.update.titre!="") ||
            (this.itemUpdate.information!=undefined && this.update.nom!=this.itemUpdate.information.nom && this.update.nom!="")
            || (this.itemUpdate.information!=undefined && this.update.prenom!=this.itemUpdate.information.prenom && this.update.prenom!="")
            || (this.itemUpdate.information!=undefined && this.update.remarque!=this.itemUpdate.information.remarque && this.update.remarque!="")
            || (this.itemUpdate.information!=undefined && this.update.naissance!=this.itemUpdate.information.naissance && this.update.naissance!="")
            || (this.itemUpdate.prix!=undefined && this.update.statut!=this.itemUpdate.prix.statut && this.update.statut!="")
            || (this.itemUpdate.prix!=undefined && this.update.mode!=mode_prix && this.update.mode!=null)
            // || (this.itemUpdate.prix!=undefined && this.update.mode!=parseInt(this.itemUpdate.prix.select_mode) && this.update.mode!=null)
            || (this.itemUpdate.montant_cotisation!=undefined && this.update.prix!=this.itemUpdate.montant_cotisation && this.update.prix!="")
            || (this.itemUpdate.pieceJointe!=undefined && this.update.valid_certif!=this.itemUpdate.pieceJointe.valid && this.update.valid_certif!=undefined)
            ){
                // console.log("update 1",true)
                this.update.save=true
            }else{
                // console.log("update 1",false)
                this.update.save=false
            }
        }
        
        if(this.vue=="modifier" && this.typeForm=="contact"){
            if(
            (this.itemUpdate.adresse!=undefined && this.update.adresse!=this.itemUpdate.adresse && this.update.adresse!="")
            || (this.itemUpdate.cp!=undefined && this.update.cp!=this.itemUpdate.cp && this.update.cp!="")
            || (this.itemUpdate.ville!=undefined && this.update.ville!=this.itemUpdate.ville && this.update.ville!="")
            || (this.itemUpdate.email!=undefined && this.update.email!=this.itemUpdate.email && this.update.email!="")
            || (this.itemUpdate.tel_mobile!=undefined && this.update.tel!=this.itemUpdate.tel_mobile && this.update.tel!="")
            ){
                // console.log("update 2",true)
                this.update.save=true
            }else{
                // console.log("update 2",false)
                this.update.save=false
            }
        }
        
        if(this.vue=="modifier" && this.typeForm=="responsable"){
            if(
            (this.itemUpdate.titre!=undefined && this.update.titre!=this.itemUpdate.titre && this.update.titre!="")
            || (this.itemUpdate.nom!=undefined && this.update.nom!=this.itemUpdate.nom && this.update.nom!="")
            || (this.itemUpdate.prenom!=undefined && this.update.prenom!=this.itemUpdate.prenom && this.update.prenom!="")
            ){
                // console.log("update 2",true)
                this.update.save=true
            }else{
                // console.log("update 2",false)
                this.update.save=false
            }
        }
        
        if(this.vue=="modifier" && this.typeForm=="urgence"){
            if(
            (this.itemUpdate.titre!=undefined && this.update.titre!=this.itemUpdate.titre && this.update.titre!="")
            || (this.itemUpdate.nom!=undefined && this.update.nom!=this.itemUpdate.nom && this.update.nom!="")
            || (this.itemUpdate.prenom!=undefined && this.update.prenom!=this.itemUpdate.prenom && this.update.prenom!="")
            || (this.itemUpdate.tel_mobile!=undefined && this.update.tel!=this.itemUpdate.tel_mobile && this.update.tel!="")
            ){
                // console.log("update 2",true)
                this.update.save=true
            }else{
                // console.log("update 2",false)
                this.update.save=false
            }
        }
        
        if(this.vue=="modifier" && this.typeForm=="optin"){
            if(
            (this.itemUpdate.droit_image!=undefined && this.update.droitImage!=this.itemUpdate.droit_image)
            || (this.itemUpdate.licence_fftda!=undefined && this.update.licence!=this.itemUpdate.licence_fftda)
            || (this.itemUpdate.reglement_club!=undefined && this.update.reglement!=this.itemUpdate.reglement_club)
            ){
                console.log("update 2",true)
                this.update.save=true
            }else{
                console.log("update 2",false)
                this.update.save=false
            }
        }
        
        if(this.vue=="modifier" && this.typeForm=="affiliation"){
            this.update.save = false
            let save = []
            for(let i=0;i<this.groupAffiliation.length;i++){
                let val = this.groupAffiliation[i]
                let key = Object.keys(val)
                // console.log("this.update.creneau test",this.update.creneau[key])
                // console.log("this.update.creneau test 2",this.update.creneau[this.update.categorie[key]])
                // console.log("this.update.creneau test 3",this.update.creneau)

            //test 3
            if(
            (this.update.categorie[key]!=this.itemUpdate.categorie[key])
            || (this.update.salle[key]!=this.itemUpdate.salle[key]) 
            
            //// || (!this.isEqual(this.update.creneau[key],this.itemUpdate.creneau[key]))
            // || (!this.isEqual(this.update.creneau[key],this.itemUpdate.creneau[key]) && this.update.creneau[key].length && this.itemUpdate.creneau[key])
            ){
                console.log("this.update.creneau",this.update.creneau)
                

                delete this.update.creneau[key]
                // this.itemUpdate.creneau = {}
                console.log("update 2",true)
                let options = {ref_inscription:this.update.saison[key], type:this.update.typeAffiliation[key],categorie:this.update.categorie[key],salle:this.update.salle[key]}
                this.update.categorieSelect[key]=await this.getCategorieByArrayTypeInscription(options)
                this.update.salleSelect[key]=await this.getSalleByArraySaisonTypeCategorie(options)
                this.update.creneauSelect[key]=await this.getCreneauByArraySaisonTypeCategorieSalle(options)
                
                console.log("this.update.creneau[this.update.categorie[key]]",this.update.creneau[this.update.categorie[key]])
                console.log("this.itemUpdate.creneau[this.itemUpdate.categorie[key]]",this.itemUpdate.creneau[this.itemUpdate.categorie[key]])

                if(!this.isEqual(this.update.creneau[this.update.categorie[key]],this.itemUpdate.creneau[this.itemUpdate.categorie[key]]) && this.update.creneau[this.update.categorie[key]] && this.itemUpdate.creneau[this.itemUpdate.categorie[key]]){
                // if(!this.isEqual(this.update.creneau[key],this.itemUpdate.creneau[key]) && this.update.creneau[key].length && this.itemUpdate.creneau[key]){
                    
                    save.push(true)
                }
            }else{
                console.log("update 2",false)

                let options = {ref_inscription:this.update.saison[key], type:this.update.typeAffiliation[key],categorie:this.update.categorie[key],salle:this.update.salle[key]}
                this.update.categorieSelect[key]=await this.getCategorieByArrayTypeInscription(options)
                this.update.salleSelect[key]=await this.getSalleByArraySaisonTypeCategorie(options)
                
                this.update.creneauSelect[key]=await this.getCreneauByArraySaisonTypeCategorieSalle(options)
                
                if(!this.isEqual(this.update.creneau[this.update.categorie[key]],this.itemUpdate.creneau[this.itemUpdate.categorie[key]]) && this.update.creneau[this.update.categorie[key]] && this.itemUpdate.creneau[this.itemUpdate.categorie[key]]){
                // if(!this.isEqual(this.update.creneau[key],this.itemUpdate.creneau[key]) && this.update.creneau[key].length && this.itemUpdate.creneau[key]){
                    
                    save.push(true)
                }else{
                    save.push(false)
                }
                
            }
            // test 3
            }

            save.forEach(el=>this.update.save=this.update.save || el)
        }

        // if(this.vue=="ajouter" && this.typeForm=="affiliation"){
        //     if(this.add.creneau.length){
        //         this.add.save = true
        //     }else{
        //         this.add.save = false
        //     }
        // }

    },

    async controlAdd(){
       if(this.vue=="ajouter" && this.typeForm=="affiliation"){
            // this.add.creneau = []
            console.log("this.add.salle",this.add.salle)
            console.log("this.add.creneau",this.add.creneau)
            let options = {ref_inscription:this.add.saison, type:this.add.type,categorie:this.add.categorie,salle:this.add.salle}
            // this.add.categorieSelect = await this.getCategorieByArrayTypeInscription(options)
            
            this.add.salleSelect = await this.getSalleByArraySaisonTypeCategorie(options)
            
            let optionsSelect = {ref_inscription:this.add.saison, type:this.add.type,categorie:this.add.categorie,salle:this.add.salleSelect.map(el=>el.value)}
            
            // this.add.creneauSelect = await this.getCreneauByArraySaisonTypeCategorieSalle(optionsSelect) 
            await this.getCreneauByArraySaisonTypeCategorieSalle(optionsSelect) 

              
            if(this.add.preSelectCreneau.length && this.add.preSelectSalle.length){
                console.log("readonly","yes")
                this.add.creneau = this.add.preSelectCreneau
                this.add.salle = this.add.preSelectSalle
                this.add.readonly = true
                this.add.creneauSelect = await this.getCreneauByArraySaisonTypeCategorieSalle(optionsSelect) 
            }else {
                console.log("readonly","no")
                this.add.readonly = false
                console.log("this.add.salle 2",this.add.salle)
                // if(this.add.salle.length){
                let optionsCreneau = {ref_inscription:this.add.saison, type:this.add.type,categorie:this.add.categorie,salle:this.add.salle}
                this.add.creneauSelect = await this.getCreneauByArraySaisonTypeCategorieSalle(optionsCreneau)
                // }
            }
        } 
    },

    assignUpdate(item){
      // info
      if(this.typeForm==""){
        this.update.ref=item.ref_licencie
        this.update.ref_cotisation=item.ref_cotisation
        this.update.saison=item.ref_inscription
        this.update.type=item.type
        this.update.titre=item.information.titre
        this.update.nom=item.information.nom
        this.update.prenom=item.information.prenom
        this.update.naissance=item.information.naissance
        this.update.prix=item.montant_cotisation
        this.update.statut=item.prix.statut
        this.update.mode=item.prix.select_mode==null ? null : item.prix.select_mode!=null && !Array.isArray(JSON.parse(item.prix.select_mode)) ?[parseInt(item.prix.select_mode)]:JSON.parse(item.prix.select_mode)
        // this.update.mode=item.prix.select_mode!=null?parseInt(item.prix.select_mode):null
        this.update.remarque=item.information.remarque
        this.update.valid_certif=item.pieceJointe.valid
        this.update.ref_pj=item.pieceJointe.ref
      }

      // contact
      if(this.typeForm=="contact"){
        this.update.ref=item.ref_licencie
        this.update.ref_contact=item.ref
        this.update.adresse=item.adresse
        this.update.cp=item.cp
        this.update.ville=item.ville
        this.update.email=item.email
        this.update.tel=item.tel_mobile
      }

        // Responsable
        if(this.typeForm=="responsable"){
        this.update.ref_responsable=item.ref
        this.update.titre=item.titre
        this.update.nom=item.nom
        this.update.prenom=item.prenom
      }
        
        // Urgence
        if(this.typeForm=="urgence"){
        this.update.ref_contactUrgence=item.ref
        this.update.titre=item.titre
        this.update.nom=item.nom
        this.update.prenom=item.prenom
        this.update.tel=item.tel_mobile
      }
       
        // optin
        if(this.typeForm=="optin"){
        this.update.ref_optin=item.ref
        this.update.droitImage=item.droit_image
        this.update.reglement=item.reglement_club
        this.update.licence=item.licence_fftda
      }

    },

    controlSelect(){},

    async searchByRef(){
        if(this.voir.ref!=""){
            let options = {ref_cotisation:this.voir.ref}
            let member =  await Membres.getByRefCotisation(options)
            if(member){
                this.selected = []
                this.membre = []
                this.membre = member.reverse()
                console.log("this.membre",this.membre)
            }
        }
    },
    async searchByName(){
        if(this.voir.nom!=""){
            let options = {nom:this.voir.nom.toLowerCase()}
            let member =  await Membres.getByName(options)
            if(member){
                this.selected = []
                this.membre = []
                this.membre = member.reverse()
                console.log("this.membre",this.membre)
            }
        }
    },
    selectAllCrenneau(model,model1,option) {
        if(this.vue=="modifier"){
        this.update[model][model1] = this.update[model][model1].length==option.length?[]:option.map(el=>el.value)
        }

        if(this.vue=="ajouter"){
        this.add[model]= this.add[model].length==option.length?[]:option.map(el=>el.value)
        }
    },
    selectAll(model,option) {
      this[this.vue][model] = this[this.vue][model].length==option.length?[]:option.map(el=>el.value)
      if(this.vue=='voir' && this.voir.saison!=""){
        this.trash.save = false
        this.selected = []
        let options = {ref_inscription:this.voir.saison, type:this.voir.type, categorie:this.voir.categorie,salle:this.voir.salle}
        this.getCategorieByArrayTypeInscription(options)
        this.getSalleByArraySaisonTypeCategorie(options)
        this.getCreneauByArraySaisonTypeCategorieSalle(options)
      }
    },

    capitalization(string){
      return string[0].toUpperCase() + string.slice(1);
    },    
    
    controlVoir(){
      console.log("test voir",this.voir.type)
      if(this.vue=='voir' && this.voir.saison!=""){
        this.trash.save = false
        this.selected = []
        let options = {ref_inscription:this.voir.saison, type:this.voir.type,categorie:this.voir.categorie,salle:this.voir.salle}
        this.getCategorieByArrayTypeInscription(options)
        this.getSalleByArraySaisonTypeCategorie(options)
        this.getCreneauByArraySaisonTypeCategorieSalle(options)
      }    
    },    

    async getCreneauByArraySaisonTypeCategorieSalle(options){
      let arrayCreneau = await Horaire_days.getCreneauxByArraySaisonTypeCategorieSalle(options)
      console.log("arrayCreneau.data",arrayCreneau)
      this.creneau = []
      
      this.add.preSelectCreneau = []
      this.add.preSelectSalle = []

      arrayCreneau.forEach(element => {
        if(element.readonly){
            this.add.preSelectCreneau.push(element.ref)
        }
        // this.categorie[0].value.push(element.ref)
        this.creneau.push({text:`${element.jour} - ${element.creneau}`,value:element.ref})
      });

      if(this.add.preSelectCreneau.length){
        this.add.preSelectSalle = options.salle
        }
      return this.creneau
    }, 
    
    async getCategorieByArrayTypeInscription(options){
      let arrayCategorie = await Categories.getByArrayTypeAndIscription(options)
      console.log("arrayCategorie.data",arrayCategorie)
      this.categorie = []
      arrayCategorie.forEach(element => {
        // this.categorie[0].value.push(element.ref)
        this.categorie.push({text:`${this.capitalization(element.niveau)} (${element.startAge}${element.endAge?'/'+element.endAge+' ans':''})`,value:element.ref})
      });
      return this.categorie
    }, 

    async getSalleByArraySaisonTypeCategorie(options){
      let arraySalle = await Horaires.getByArraySaisonTypeCategorie(options)
      this.salle = []
      arraySalle.forEach(el=>{
        // this.salle[0].value.push(el.ref)
        this.salle.push({text:el.nom,value:el.ref})
      })
      return this.salle
    },   

    async getSaison(){
      let arrayInscription = await Inscriptions.get()
      console.log("arrayInscription.data",arrayInscription)
      arrayInscription.forEach(element => {
        // this.saison[0].value.push(element.ref)
        this.saison.push({text:element.saison,value:element.ref})
      });
      this.inscriptionData = arrayInscription
    },

    // async getSaisonByRef(ref){
    //   let inscription = await Inscriptions.getByRef({ref_inscription:ref})
    //   return inscription
    // }
  }
  }
</script>
<style  lang="scss">
//  .custom-checkbox {
//     .v-input--selection-controls__input .v-icon{
//         font-size:11px;
//         width:auto;
//     }
//  }
</style>